<template>
  <NetworkMessages />
</template>
<script>
import NetworkMessages from "@candidate/NetworkMessages";

export default {
  components: {
    NetworkMessages,
  },
};
</script>
